@import "react-big-calendar/lib/css/react-big-calendar.css";

html, body, #root {
  overflow: hidden;
  height: 100%;
}


.App {
  //text-align: center;
  height: 100%;
  overflow: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.border-radius-preview {
  border-radius: 2rem;
}

.curvedinside {
  -webkit-clip-path: polygon(71% 0%, 83% 11%, 92% 23%, 98% 37%, 100% 51%, 97% 64%, 90% 78%, 84% 88%, 72% 100%, 0% 100%, 8% 89%, 14% 78%, 20% 66%, 25% 50%, 24% 38%, 19% 23%, 11% 11%, 0% 0%);
  clip-path: polygon(71% 0%, 83% 11%, 92% 23%, 98% 37%, 100% 51%, 97% 64%, 90% 78%, 84% 88%, 72% 100%, 0% 100%, 8% 89%, 14% 78%, 20% 66%, 25% 50%, 24% 38%, 19% 23%, 11% 11%, 0% 0%);
}

.progress_circular {
  stroke-width: 6px;
  stroke: #ddd;
  stroke-dasharray: 0.6 3;
  stroke-dashoffset: 1.6;
}

.responsive_height {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.label_color {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
}

.MuiDialog-container {
  .MuiDialog-paper {
    border-radius: 2rem;
  }
}

#nav_container:after{
  content: "beta";
  position: fixed;
  width: 150px;
  height: 25px;
  background: var(--bg-secondary);
  top: 30px;
  left: -30px;
  text-align: center;
  font-size: 25px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 27px;
  transform:rotate(-45deg);
}

@media screen and (max-width: 768px) {
  .hide_on_mobile {
    display: none;
    background-color: lightblue;
  }

  .center_mobile {
    justify-content: center;
    align-items: center;
  }

  .modal_width_mobile {
    width: 90%;
  }
}



@media screen and (min-width: 768px) {
  .thin_border {
    border-width: 1px;
  }

  .modal_width_mobile {
    width: 25%;
  }
}

.min-h-screen_custom {
  min-height: 87vh
}

.no-arrow::-webkit-outer-spin-button,
.no-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrow {
  -moz-appearance: textfield;
}

.contract_rendering {
  h1 {
    text-align:center;
    width: 100%;
    font-weight: 700;
    color: #0284c7;
    font-size: 1.125rem;
  }

  h2 {
    font-weight: 700;
    margin-left: 10px;
    color: #0284c7;
    font-size: 1.125rem;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
}