body {
  margin: 0;
  font-family: 'Helvetica',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --bg-secondary: #0284c7;
}

[data-platform="Breedj"] {
  --bg-secondary: #002496;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@import "@fortawesome/fontawesome-free/css/all.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

fieldset {
  border-radius: 25px !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-clear-button {
  display: none;
}

input[type=time]::-webkit-datetime-edit-ampm-field { display: none; }


.myDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border-color: red;
}

// .MuiTextField-root {
//   .MuiFormLabel-root {
//     top: -5px;

//     &.Mui-focused, &.MuiInputLabel-shrink {
//       top: 0;
//     }
//   }

//   .MuiInputBase-root {
//     height: 50px;

//     &>input,
//     &>fieldset {
//       height: 50px;
//     }
//   }
// }

.bdj-fileupload-hidden {
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  &.file-uploaded {
    width: calc(100% - 40px);
  }
}